@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap");

* {
  margin: 0;
  padding: 0;
}

html {
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
}

body {
  overflow-x: hidden;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  z-index: -1;
  pointer-events: none;
}

.loading-bar {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  background: #000;
  transform: scaleX(0.3);
  transform-origin: top left;
  transition: transform 0.5s;
  z-index: 99999;
}

.loading-bar.ended {
  transform: scaleX(0);
  transform-origin: 100% 0;
  transition: transform 1.5s ease-in-out;
  z-index: 999;
}

.container-fluid {
  display: none;
  opacity: 0;
  user-select: none;
}

.enter {
  height: 100vh;
  visibility: hidden;
}

.enter-button {
  z-index: 9999;
}

.frontal-lobe {
  height: 100vh;
  position: relative;
  display: block;
  clip-path: polygon(
    0 0,
    85% 0,
    72.5% 23%,
    72.5% 80%,
    85% 100%,
    0 100%,
    0% 80%,
    0% 20%
  );
  background-color: hsl(216, 21%, 16%);
  scroll-snap-align: center;
}

.article-frontal-lobe {
  color: white;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 37.5%;
  font-family: "Oswald", sans-serif;
  background-color: rgba(0, 0, 0, 0.2);
}

.occipital-lobe {
  height: 100vh;
  position: relative;
  display: block;
  clip-path: polygon(100% 0, 100% 100%, 15% 100%, 27.5% 76%, 27.5% 24%, 15% 0);
  background-color: hsl(216, 21%, 16%);
  scroll-snap-align: center;
}

.article-occipital-lobe {
  color: white;
  position: absolute;
  top: 50%;
  transform: translate(-10%, -50%);
  left: 37.5%;
  font-family: "Oswald", sans-serif;
  background-color: rgba(0, 0, 0, 0.2);
}

.parietal-lobe {
  height: 100vh;
  position: relative;
  display: block;
  clip-path: polygon(
    0 0,
    85% 0,
    72.5% 23%,
    72.5% 80%,
    85% 100%,
    0 100%,
    0% 80%,
    0% 20%
  );
  background-color: hsl(216, 21%, 16%);
  scroll-snap-align: center;
}

.article-parietal-lobe {
  color: white;
  position: absolute;
  top: 50%;
  transform: translate(-10%, -50%);
  left: 37.5%;
  font-family: "Oswald", sans-serif;
  background-color: rgba(0, 0, 0, 0.2);
}

.temporal-lobe {
  height: 100vh;
  position: relative;
  display: block;
  clip-path: polygon(100% 0, 100% 100%, 15% 100%, 27.5% 76%, 27.5% 24%, 15% 0);
  background-color: hsl(216, 21%, 16%);
  scroll-snap-align: center;
}

.article-temporal-lobe {
  color: white;
  position: absolute;
  top: 50%;
  transform: translate(-10%, -50%);
  left: 37.5%;
  font-family: "Oswald", sans-serif;
  background-color: rgba(0, 0, 0, 0.2);
}

.headline {
  font-size: 5vw;
}

.subject {
  font-size: 2.5vw;
  padding-left: 35px;
  padding-right: 35px;
}

.fl-elements {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 37.5%;
  z-index: -1;
}

.anime-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
}

.anime-triangle {
  position: absolute;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background-color: white;
  width: 30px;
  height: 30px;
}

.anime-star {
  position: absolute;
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
  background-color: white;
  width: 30px;
  height: 30px;
}

.ol-elements {
  position: absolute;
  top: 50%;
  transform: translate(-10%, -50%);
  left: 37.5%;
  z-index: -1;
}

.anime-heart {
  position: absolute;
  clip-path: url(#heart-path);
  background-color: white;
  width: 30px;
  height: 30px;
}

.anime-crescent {
  position: absolute;
  clip-path: url(#crescent-path);
  background-color: white;
  width: 30px;
  height: 30px;
}

.anime-shape {
  position: absolute;
  clip-path: url(#shape-path);
  background-color: white;
  width: 30px;
  height: 30px;
}

.pl-elements {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 37.5%;
  z-index: -1;
}

.anime-dragon {
  position: absolute;
  clip-path: url(#dragon-path);
  background-color: white;
  width: 30px;
  height: 30px;
}

.anime-shuriken {
  position: absolute;
  clip-path: url(#shuriken-path);
  background-color: white;
  width: 30px;
  height: 30px;
}

.anime-lightning {
  position: absolute;
  clip-path: url(#lightning-path);
  background-color: white;
  width: 30px;
  height: 30px;
}

.tl-elements {
  position: absolute;
  top: 50%;
  transform: translate(-10%, -50%);
  left: 37.5%;
  z-index: -1;
}

.anime-pacman {
  position: absolute;
  clip-path: url(#pacman-path);
  background-color: white;
  width: 30px;
  height: 30px;
}

.anime-alien {
  position: absolute;
  clip-path: url(#alien-path);
  background-color: white;
  width: 30px;
  height: 30px;
}

.anime-gamecontrol {
  position: absolute;
  clip-path: url(#gamecontrol-path);
  background-color: white;
  width: 30px;
  height: 30px;
}

.anime-leaf {
  position: absolute;
  clip-path: url(#leaf-path);
  background-color: white;
  width: 30px;
  height: 30px;
}

.anime-butterfly {
  position: absolute;
  clip-path: url(#butterfly-path);
  background-color: white;
  width: 30px;
  height: 30px;
}

.anime-clover-leaf {
  position: absolute;
  clip-path: url(#clover-leaf-path);
  background-color: white;
  width: 30px;
  height: 30px;
}

footer {
  height: 7vh;
  display: flex;
  background-color: hsl(213, 22%, 10%);
  color: white;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
}

/**
 * @file
 * Main responsive stylesheet file.
 */

/*****************************************************************************
  * Large devices (large desktops, 1200px and up)
  ****************************************************************************/
/* @media (max-width: 1200px) {
} */
/* Large devices (large desktops, 1200px and up) end */

/*****************************************************************************
  * Medium devices (desktops, 1100px and up)
  ****************************************************************************/
@media (max-width: 1100px) {
  .headline {
    font-size: 6vw;
  }

  .subject {
    font-size: 3vw;
    padding-left: 30px;
    padding-right: 30px;
  }
}
/* Medium devices (desktops, 1100px and up) end */

/*****************************************************************************
  * Small devices (tablets, 768px and up)
  ****************************************************************************/
@media (max-width: 768px) {
  .frontal-lobe {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 75% 60%, 25% 60%, 0 100%);
  }
  .occipital-lobe {
    clip-path: polygon(25% 40%, 75% 40%, 100% 0, 100% 100%, 0 100%, 0 0);
  }
  .parietal-lobe {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 75% 60%, 25% 60%, 0 100%);
  }
  .temporal-lobe {
    clip-path: polygon(25% 40%, 75% 40%, 100% 0, 100% 100%, 0 100%, 0 0);
  }

  .article-frontal-lobe {
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .article-occipital-lobe {
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .article-parietal-lobe {
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .article-temporal-lobe {
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
  }

  .headline {
    font-size: 7vw;
  }

  .subject {
    font-size: 3.5vw;
    padding-left: 25px;
    padding-right: 25px;
  }
}
/* Small devices (tablets, 768px and up) end */

/*****************************************************************************
 * Extra small devices (phones, up to 520px)
 ****************************************************************************/
@media (max-width: 520px) {
  .headline {
    font-size: 10vw;
  }

  .subject {
    font-size: 5vw;
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* Extra small devices (phones, up to 767px) end */

/*****************************************************************************
  * Various other styles
  ****************************************************************************/

/* Various other styles end */
